// MDB FREE
@import "mdb-free";

// PRO
@import "pro/variables";
@import "pro/buttons";
@import "pro/social-buttons";
@import "pro/tabs";
@import "pro/cards-basic";
@import "pro/dropdowns";
@import "pro/navbars";
@import "pro/scrollspy";
@import "pro/modules/lightbox/module";
@import "pro/chips";
@import "pro/msc";
@import "pro/forms";
@import "pro/radio";
@import "pro/checkbox";
@import "pro/material-select";
@import "pro/switch";
@import "pro/file-input";
@import "pro/range";
@import "pro/input-group";
@import "pro/autocomplete";
@import "pro/accordion-basic";
@import "pro/modules/accordion-extended/module";
@import "pro/modules/parallax/module";
@import "pro/sidenav";
@import "pro/ecommerce";
@import "pro/carousels";
@import "pro/blog";
@import "pro/toasts";
@import "pro/animations";
@import "pro/progress";
@import "pro/scrollbar";
@import "pro/modules/megamenu/module";
@import "pro/tooltips";
// Your custom skin
@import "custom-skin";
@import "pro/skins";
@import "pro/depreciated";

// Picker
@import "pro/picker/default";
@import "pro/picker/default-time";
@import "pro/picker/default-date";

// Sections
@import "pro/sections/templates";
@import "pro/sections/social";
@import "pro/sections/team";
@import "pro/sections/testimonials";
@import "pro/sections/magazine";
@import "pro/sections/pricing";
@import "pro/sections/contacts";

// Pro addons
@import "addons-pro/steppers";
@import "addons-pro/timeline";
@import "addons-pro/cards-extended";
@import "addons-pro/chat";
@import "addons-pro/charts/module";
@import "addons-pro/vector-map";
