// Your custom styles

//Search Results

.tag-select-inner .col {
    cursor:pointer;
}

.type-selected {
    border: 3px solid black;
}

.card-body h4 {
    height:55px;
    font-size: 20px;
}

.sort-by .col-12{
    text-align: center !important;
}

a.vendor-card{
    color: black;
}

a.vendor-card h5{
    color: $default-color;
}

.vendor-card .card .card-body .card-text {
    font-size: 0.8rem;
}

.container.tag-select {
    width: 100%;
    float: left;
    margin: 0;
    max-width: none;
    overflow-y: hidden;
}

@include media-breakpoint-up(sm) { 
    .sort-by .col-12.text-sm-center{
        text-align: center !important;
    }
    .sort-by .col-12.text-sm-left{
        text-align: left !important;
    }
    .sort-by .col-12.text-sm-right{
        text-align: right !important;
    }
    .sort-by .col-12 button{
        float: left;
    }
 }



//Homepage
.home-type h2 {
    font-size:2.5rem !important;
}

.home-type p {
    font-family: zing_rust_demobase;
    font-size: 1.1rem;
}

.pop-up-link {
    font-family: zing_rust_demobase;  
}

a.pop-up-link {
    color: black;
}

.startd-form .md-form{
    margin-top: 0
}

.startd-form p {
    margin: 0;
}




// Vendor page

.product h4{
    margin-bottom: 0px;
    height: auto;
}

.add-ons .add-on{
    border-bottom: 1px solid lightgrey;
    float: left;
    width: 100%;
}

.add-ons .add-on:last-child{
    border-bottom: none;
    padding-bottom: 0 !important;
}

.add-ons .add-on:first-child{
    padding-top: 0 !important;
}



// Event Details

.event-popup .md-form {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.event-details {
    cursor: pointer;
}



// Search Box

.event-type .mdb-main-label {
    color: black !important;
}

.event-type .dropdown-content li > span {
    color: black;
    font-weight: bold;
}


// Sticky event details
.sticky-event {
    position: fixed;
    top: 64px;
    width: 100%;
    background: white;
    z-index: 100;
    margin: 0 !important;
}

.has-sticky-search{
    padding-top: 120px !important;
}


// Generic

.social {
    font-size: 30px;
}

.header-logo{
    font-family: zing_rust_demobase;
    letter-spacing: -3px;
    font-size:2.7rem;
}

.header-logo span{
    color: $primary-color;
}

.navbar-brand {
    padding: 0;
}

.navbar {
    padding: 0 1rem;
}

.zing-rust {
    font-family: zing_rust_demobase;
}

input::after{
    border-bottom-color:red !important
}