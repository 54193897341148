/** Utility **/

.clear-left {
    clear:left;
}






/** Booking-a-slider CSS **/

.slideshow-height {
    height:40px;
    line-height:40px;
}

.slideshow-wrapper {
    width: 100px;
    overflow:hidden;
    margin: 0 auto 0 10px;
    padding-left: 10px;
    display: inline-block;
}

.slideshow-wrapper ul {
    margin:0;
    padding:0;
    width: 400px;
    -webkit-animation: slideshow 15s infinite;
    -moz-animation: slideshow 15s infinite;
    -o-animation: slideshow 15s infinite;
    animation: slideshow 15s infinite;
}

.slides .slide {
    width:90px;
    display:inline-block;
    margin:0;
    padding:0;
    float: left;
}

/* Animation */
@-webkit-keyframes slideshow {
    0%   	{ margin-left: 0.5%; }
    23%  	{ margin-left: 0.5%; }
    25% 	{ margin-left: -100%; }
    48% 	{ margin-left: -100%; }
    50% 	{ margin-left: -200%; }
    73% 	{ margin-left: -200%; }
    75% 	{ margin-left: -300%; }
    98% 	{ margin-left: -300%; }
}
@-moz-keyframes slideshow {
    0%   	{ margin-left: 0.5%; }
    23%  	{ margin-left: 0.5%; }
    25% 	{ margin-left: -100%; }
    48% 	{ margin-left: -100%; }
    50% 	{ margin-left: -200%; }
    73% 	{ margin-left: -200%; }
    75% 	{ margin-left: -300%; }
    98% 	{ margin-left: -300%; }
}
@-o-keyframes slideshow {
    0%   	{ margin-left: 0.5%; }
    23%  	{ margin-left: 0.5%; }
    25% 	{ margin-left: -100%; }
    48% 	{ margin-left: -100%; }
    50% 	{ margin-left: -200%; }
    73% 	{ margin-left: -200%; }
    75% 	{ margin-left: -300%; }
    98% 	{ margin-left: -300%; }
}

@keyframes slideshow {
    0%   	{ margin-left: 0.5%; }
    23%  	{ margin-left: 0.5%; }
    25% 	{ margin-left: -100%; }
    48% 	{ margin-left: -100%; }
    50% 	{ margin-left: -200%; }
    73% 	{ margin-left: -200%; }
    75% 	{ margin-left: -300%; }
    98% 	{ margin-left: -300%; }
}
