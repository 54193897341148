// Your custom variables

$image-path: "../../img" !default;
$lightbox-images-path: '../../../../img/' !default;


$default-color: #FF5757;
$default-color-dark: #EA3D3D;

$primary-color: #00C2CB;
$primary-color-dark: #04ADAD;

$secondary-color: #b971d6;
$secondary-color-dark: #9300ce;

@font-face {
    font-family: 'zing_rust_demobase';
    src: url('../fonts/zingrustdemo-base.woff') format('woff2'),
         url('../fonts/zingrustdemo-base.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}


h1, h2, h3, h4, h5 {
    font-family: 'zing_rust_demobase', cursive;
}

.primary-text {
    color: $primary-color;
}

.primary-text a {
    color: $primary-color !important;
}